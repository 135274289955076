import "../scss/main.scss";
import "dropzone/dist/dropzone.css";

const Dropzone = require("dropzone").default;

const ready = (callback) => {
  if (document.readyState != "loading") callback();
  else document.addEventListener("DOMContentLoaded", callback);
};

/**
 * Returns the cookie value of the specified name.
 */
const getCookie = (name) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};

ready(() => {
  Dropzone.autoDiscover = false;

  const imgUploadDropzone = new Dropzone("div#myDropzone", {
    url: "/xhr-upload",
    paramName: "img", // The name that will be used to transfer the file
    maxFiles: 1,
    forceFallback: false,
    acceptedFiles: "image/*",
    headers: { "X-CSRFToken": getCookie("up_csrftoken") },
  });

  imgUploadDropzone.on("error", (err, msg) => {
    document.getElementById("error-row").classList.remove("hidden");
    document.getElementById("error-msg").innerText = msg;
  });

  imgUploadDropzone.on("success", (file, response) => {
    if ("url" in response) {
      console.log(response);
      const baseUrl = window.location.href.replace(/\/$/, "");
      window.location = `${baseUrl}${response.url}`;
    }
  });

  document.onpaste = function (event) {
    const items = (event.clipboardData || event.originalEvent.clipboardData)
      .items;
    for (let index in items) {
      const item = items[index];
      if (item.kind === "file") {
        // adds the file to your dropzone instance
        imgUploadDropzone.addFile(item.getAsFile());
      }
    }
  };
});
